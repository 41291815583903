import { URLS } from "res/URLS";
import { ModuleAccess } from "shared/typings/permissions";
import Path from "./paths";

export interface SidemenuRoute {
  id: number;
  text: string;
  icon: string;
  url: string;
  permissaoAcesso: ModuleAccess | ModuleAccess[];
}

const sidemenuRoutes: SidemenuRoute[] = [

  {
    id: 2,
    text: "Comercial",
    icon: "icon-comercial",
    url: Path.COMERCIAL,
    permissaoAcesso: "comercial.listagem",
  },
  {
    id: 5,
    text: "Rolos e Fardões",
    icon: "icon-rolos-ut",
    url: URLS.ROLOS_FARDOES,
    permissaoAcesso: "rolos-fardos.web.visualizar",
  },
  {
    id: 6,
    text: "Sessões",
    icon: "icon-sessoes",
    url: URLS.SESSOES,
    permissaoAcesso: "sessao.visualizar",
  },
  {
    id: 7,
    text: "Lotes",
    icon: "icon-lote",
    url: "/lotes",
    permissaoAcesso: "lote.web.visualizar",
  },
  {
    id: 8,
    text: "Cargas",
    icon: "icon-icone-cargas-2",
    url: URLS.CARGAS,
    permissaoAcesso: "carga.web.visualizar",
  },
  {
    id: 9,
    text: "Carregamentos",
    icon: "icon-icone-carregamento",
    url: URLS.CARREGAMENTOS,
    permissaoAcesso: "carregamentos.listagem",
  },
  {
    id: 10,
    text: "Permissões",
    icon: "fas fa-user",
    url: URLS.PERMISSOES,
    permissaoAcesso: "usuario.web.visualizar",
  },
  {
    id: 11,
    text: "Painel de integração",
    icon: "fas fa-sync",
    url: URLS.PAINEL_INTEGRACAO,
    permissaoAcesso: "painel-integracao.web.visualizar",
  },
  {
    id: 12,
    text: "Fila de Integração",
    icon: "fas fa-exchange-alt",
    url: URLS.FILA_INTEGRACAO,
    permissaoAcesso: "fila-integracao.web.visualizar",
  },
  {
    id: 13,
    text: "Inventários para Aprovação",
    icon: "fas fa-clipboard-list",
    url: URLS.INVENTARIOS_APROVACAO,
    permissaoAcesso: "aprovador-inventario.web.visualizar",
  },
];

export default sidemenuRoutes;
