import { pageSwitch } from 'app/ConferenciaCampo/screens/Fazendas/pageSwitch';
import SolicitacaoRoute from 'app/TakeUp/SolicitacoesEdicao/components/SolicitacaoRoute';
import { SnackbarProvider } from 'notistack';
import React, { Suspense } from 'react';
import { AuthenticationState, AzureAD } from 'react-aad-msal';
import { Route, Switch } from 'react-router-dom';
import { URLS } from 'res/URLS';
import Loader from 'shared/Layout/components/Loader';
import Home from '../../app/Home/Home';
import LoginModule from '../../app/Login';
import Layout from '../../shared/Layout';
import { authProvider } from '../msal/authProvider';
import Path from './paths';
import PrivateRoute from './PrivateRoute';

const OrdensColheita = React.lazy(() => import('app/OrdensColheita/screens/OrdensColheita'));
const CadastroEquipes = React.lazy(() => import('app/CadastroEquipes/screens/CadastroEquipes'));
const CadastroLocaisArmazenamento = React.lazy(() =>
  import('app/CadastroLocaisArmazenamento/screens/CadastroLocaisArmazenamento'),
);
const CadastroOcupacoes = React.lazy(() =>
  import('app/CadastroOcupacoes/screens/CadastroOcupacoes'),
);
const CadastroMotivosParada = React.lazy(() =>
  import('app/CadastroMotivosParada/screens/CadastroMotivosParada'),
);
const CadastroVariedades = React.lazy(() =>
  import('app/CadastroVariedades/screens/CadastroVariedades'),
);
const CadastroAlgodoeira = React.lazy(() =>
  import('app/CadastroAlgodoeira/screens/CadastroAlgodoeira'),
);
const CadastroProjecaoProducao = React.lazy(() =>
  import('app/CadastroProjecaoProducao/screens/CadastroProjecaoProducao'),
);
const CadastroFabricantesLonas = React.lazy(() =>
  import('app/CadastroFabricanteLonas/screens/Show'),
);
const CadastroAprovadoresInventario = React.lazy(() =>
  import('app/Inventario/CadastroAprovador/screens/Show'),
);
const CadastroInventarioRolosFardinhos = React.lazy(() =>
  import('app/CadastroInventarioRolosFardinhos/screens/Show'),
);
const MenuCadastros = React.lazy(() => import('app/MenusFooter/screens/MenuCadastros'));
const MenuConfig = React.lazy(() => import('app/MenusFooter/screens/MenuConfiguracoes'));
const ImportacaoHVI = React.lazy(() => import('app/Importacao/screens/ImportacaoHVI'));
const ImportacaoClassificacao = React.lazy(() => import('app/Importacao/screens/ImportacaoClassificacao'));

const ListagemRolosFardoes = React.lazy(() => import('app/RolosFardoes/ListagemRolosFardoes'));
const PainelIntegracaoIndex = React.lazy(() => import('app/PainelIntegracao/screens/Show'));
const FilaIntegracaoIndex = React.lazy(() => import('app/FilaIntegracao/screens/Show'));
const AprovacaoInventarioIndex = React.lazy(() => import('app/AprovacaoInventario/screens/Show'));
const PermissoesIndex = React.lazy(() => import('app/Permissoes/screens/Show'));
const ParametroAlgodoeiraIndex = React.lazy(() => import('app/ParametrosAlgodoeira/screens/ParametrosAlgodoeira'));
const LeituraView = React.lazy(() => import('app/Carga/screens/LeituraView'));
const ParametrosIntegracao = React.lazy(() => import('app/CadastroIntegracao/screens/Show'));
const LotsList = React.lazy(() => import('app/Lots/screens/List'));
const LotShow = React.lazy(() => import('app/Lots/screens/Show'));
const CadastroTransportador = React.lazy(() =>
  import('app/CadastroTransportador/screens/CadastroTransportador'),
);

const PortalBalancaIndex = React.lazy(() => import('app/PortalBalanca/screens/Show'));

const ConferenciaPatioIndex = React.lazy(() => import('app/Relatorios/ConferenciaPatio/screens/Show'));

const SessoesIndex = React.lazy(() => import('app/Sessoes/screens/Show'));

const MenuRelatorios = React.lazy(() => import('app/MenusFooter/screens/MenuRelatorios'));

const DetalhesSessao = React.lazy(() => import('app/Sessoes/screens/Details'));

const SelecionarSessaoDestino = React.lazy(() =>
  import('app/Sessoes/screens/SelecionarSessaoDestino'),
);

const MovimentarSessao = React.lazy(() => import('app/Sessoes/screens/MovimentarSessao'));

const ExcluirRolosSessao = React.lazy(() => import('app/Sessoes/screens/ExcluirRolos'));

const DetalhesConferenciaCampo = React.lazy(() => import('app/ConferenciaCampo/screens/Detalhes'));

const RelatorioPadroesHVI = React.lazy(() => import('app/PadroesHVI/screens/Show'));

const Comercial = React.lazy(() => import('app/Comercial/screens/ListaGrupoCliente'));

const DetalhesGrupoCliente = React.lazy(() => import('app/Comercial/screens/DetalhesGrupoCliente'));

const ImportacaoActs = React.lazy(() => import('app/ImportacaoActs/screens/Show'));

const EstoqueRelatorio = React.lazy(() => import('app/Estoque/screens/EstoqueRelatorio'));

const EstoqueComercial = React.lazy(() => import('app/Estoque/screens/EstoqueComercial'));

const EmailsBeneficiamentoDiario = React.lazy(() =>
  import('app/EmailsBeneficiamentoDiario/screens/Show'),
);

const CargaEmAndamento = React.lazy(() => import('app/Carga/components/CargaEmAndamento'));

const ListagemCarregamentos = React.lazy(() => import('app/Carregamento/screen/ListagemCarregamentos'));

const FardinhosNoPorto = React.lazy(() => import('app/Comercial/components/Carregamento/FardinhosNoPorto/screen'));

const BeneficiamentoIndex = React.lazy(() => import('app/Relatorios/Beneficiamento/screens/Show'));

const FardinhosNaoClassificados = React.lazy(() => import('app/Relatorios/FardinhosNaoClassificados/screens/Show'));

const ConferenciaEntradaProducao = React.lazy(() => import('app/Relatorios/ConferenciaEntradaProducao/screens/Show'));

const ConferenciaBlocos = React.lazy(() => import('app/Relatorios/ConferenciaBlocos/screens/Show'));

const ConferenciaPatioBeneficiados = React.lazy(() => import('app/Relatorios/ConferenciaPatioBeneficiados/screens/Show'));

const ConferenciaSessao = React.lazy(() => import('app/Relatorios/ConferenciaSessao/screens/Show'));

const ConferenciaRolos = React.lazy(() => import('app/Relatorios/ConferenciaRolos/screens/Show'));

const VolumeEstoque = React.lazy(() => import('app/Relatorios/VolumeEstoque/screens/Show'));

const SolicitacoesEstorno = React.lazy(() => import('app/Comercial/components/SolicitacoesEstorno'));

const ClassificacaoFardinhos = React.lazy(() => import('app/ClassificacaoFardinhos/screens/Show'));

export default function App() {
  return (
    <AzureAD provider={authProvider}>
      {({ authenticationState }) => {
        switch (authenticationState) {
          case AuthenticationState.Unauthenticated:
            return <Route exact path="/" component={LoginModule} />;
          case AuthenticationState.Authenticated:
            return (
              <SnackbarProvider
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                maxSnack={3}
                autoHideDuration={8000}
              >
                <Layout>
                  <Suspense fallback={<Loader />}>
                    <Switch>
                      <Route exact path="/" component={Home} />
                      <PrivateRoute
                        path="/rolos-fardos"
                        component={ListagemRolosFardoes}
                        permissionRequired="rolos-fardos.web.visualizar"
                      />
                      <PrivateRoute
                        path={URLS.SESSOES}
                        component={SessoesIndex}
                        exact
                        permissionRequired="sessao.visualizar"
                      />
                      <PrivateRoute
                        path={URLS.SESSAO_DETALHES}
                        exact
                        component={DetalhesSessao}
                        permissionRequired="sessao.visualizar"
                      />
                      <PrivateRoute
                        path={URLS.SESSAO_MOVIMENTAR}
                        exact
                        component={MovimentarSessao}
                        permissionRequired="sessao.movimentar"
                      />
                      <PrivateRoute
                        path={Path.EXCLUIR_SESSAO_ROLOS}
                        exact
                        component={ExcluirRolosSessao}
                        permissionRequired="sessao.excluir-rolos"
                      />
                      <PrivateRoute
                        path={URLS.SESSAO_MOVIMENTAR_SESSAO}
                        exact
                        component={SelecionarSessaoDestino}
                        permissionRequired="sessao.movimentar"
                      />

                      <PrivateRoute
                        path={URLS.PERMISSOES}
                        component={PermissoesIndex}
                        permissionRequired="usuario.web.visualizar"
                      />
                      <PrivateRoute
                        path={URLS.PARAMETROS_ALGODOEIRA}
                        component={ParametroAlgodoeiraIndex}
                        permissionRequired="configuracoes-maquinas.web.visualizar"
                      />
                      <PrivateRoute
                        path="/lotes"
                        component={LotsList}
                        permissionRequired="lote.web.visualizar"
                      />
                      <PrivateRoute
                        path="/lote/:id"
                        component={LotShow}
                        permissionRequired="lote.web.visualizar-detalhes"
                      />
                      <PrivateRoute
                        path={URLS.PAINEL_INTEGRACAO}
                        component={PainelIntegracaoIndex}
                        permissionRequired="painel-integracao.web.visualizar"
                      />
                      <PrivateRoute
                        path={URLS.FILA_INTEGRACAO}
                        component={FilaIntegracaoIndex}
                        permissionRequired="painel-integracao.web.visualizar"
                      />
                      <PrivateRoute
                        path={URLS.INVENTARIOS_APROVACAO}
                        component={AprovacaoInventarioIndex}
                        permissionRequired="aprovador-inventario.web.visualizar"
                      />
                      <PrivateRoute
                        path={URLS.ORDENS_COLHEITA}
                        component={OrdensColheita}
                        permissionRequired="transportador.web.visualizar"
                      />
                      <PrivateRoute
                        path={URLS.CADASTRO_EQUIPES}
                        component={CadastroEquipes}
                        permissionRequired="equipes.web.visualizar"
                      />
                      <PrivateRoute
                        path={URLS.CADASTRO_LOCAIS_ARMAZENAMENTO}
                        component={CadastroLocaisArmazenamento}
                        permissionRequired="configuracoes-maquinas.web.visualizar"
                      />
                      <PrivateRoute
                        path={URLS.CADASTRO_ALGODOEIRA}
                        component={CadastroAlgodoeira}
                        permissionRequired="cadastro-basico.web.visualizar"
                      />
                      <PrivateRoute
                        path={URLS.CADASTRO_VARIEDADES}
                        component={CadastroVariedades}
                        permissionRequired="cadastro-basico.web.visualizar"
                      />
                      <PrivateRoute
                        path={URLS.CADASTRO_OCUPACOES}
                        component={CadastroOcupacoes}
                        permissionRequired="cadastro-basico.web.visualizar"
                      />
                      <PrivateRoute
                        path={URLS.CADASTRO_PROJECAO_PRODUCAO}
                        component={CadastroProjecaoProducao}
                        permissionRequired="projecao-fazenda.web.visualizar"
                      />
                      <PrivateRoute
                        path={URLS.CADASTRO_FABRICANTES_LONAS}
                        component={CadastroFabricantesLonas}
                        permissionRequired="fabricante-lonas.web.visualizar"
                      />
                      <PrivateRoute
                        path={URLS.CADASTRO_APROVADORES_INVENTARIO}
                        component={CadastroAprovadoresInventario}
                        permissionRequired="aprovador-inventario.web.visualizar"
                      />
                      <PrivateRoute
                        path={URLS.CADASTRO_INVENTARIO_ROLOS_FARDINHOS}
                        component={CadastroInventarioRolosFardinhos}
                        permissionRequired="projecao-fazenda.web.visualizar"
                      />
                      <PrivateRoute
                        path={URLS.CADASTRO_MOTIVOS_PARADAS}
                        component={CadastroMotivosParada}
                        permissionRequired="motivo-parada.web.visualizar"
                      />
                      <PrivateRoute
                        path={URLS.MENU_CADASTROS}
                        component={MenuCadastros}
                        permissionRequired={[
                          'motivo-parada.web.visualizar',
                          'transportador.web.visualizar',
                          'projecao-fazenda.web.visualizar',
                          'fabricante-lonas.web.visualizar',
                        ]}
                      />

                      <PrivateRoute
                        path={URLS.CADASTRO_PARAMETROS}
                        component={ParametrosIntegracao}
                        permissionRequired="parametro-integracao.web.visualizar"
                      />

                      <PrivateRoute
                        path={URLS.MENU_CONFIGURACOES}
                        component={MenuConfig}
                        permissionRequired={[
                          'parametro-integracao.web.visualizar',
                          'configuracoes-maquinas.web.visualizar',
                        ]}
                      />
                      <PrivateRoute
                        path={URLS.IMPORTACAO_HVI}
                        component={ImportacaoHVI}
                        permissionRequired="importacao.web.hvi"
                      />
                      <PrivateRoute
                        path={URLS.IMPORTACAO_CLASSIFICACAO}
                        component={ImportacaoClassificacao}
                        permissionRequired="importacao.web.classificacao-visual"
                      />

                      <PrivateRoute
                        exact
                        path={URLS.LEITURA}
                        component={LeituraView}
                        permissionRequired="carga.web.leitura-tempo-real"
                      />

                      <PrivateRoute
                        path={URLS.CADASTRO_TRANSPORTADOR}
                        component={CadastroTransportador}
                        permissionRequired="transportador.web.visualizar"
                      />

                      <PrivateRoute
                        exact
                        path={URLS.CARGAS}
                        component={PortalBalancaIndex}
                        permissionRequired="carga.web.visualizar"
                      />

                      <PrivateRoute
                        exact
                        path={URLS.SOLICITACAO_EDICAO}
                        component={SolicitacaoRoute}
                        permissionRequired="comercial.take-up.solicitacoes-edicao.visualizar"
                      />

                      <PrivateRoute
                        exact
                        path={URLS.SOLICITACAO_ESTORNO_CARREGAMENTOS}
                        component={SolicitacoesEstorno}
                        permissionRequired="carregamentos.estorno.aprovar-recusar"
                      />

                      <PrivateRoute
                        exact
                        permissionRequired="relatorios.conferencia-do-patio"
                        path={URLS.CONFERENCIA_PATIO}
                        component={ConferenciaPatioIndex}
                      />

                      <PrivateRoute
                        exact
                        permissionRequired="relatorios.detalhes_beneficiamento"
                        path={URLS.BENEFICIAMENTO}
                        component={BeneficiamentoIndex}
                      />

                      <PrivateRoute
                        exact
                        permissionRequired="relatorios.dashboard.classificacao-algodao"
                        path={URLS.FARDINHOS_NAO_CLASSIFICADOS}
                        component={FardinhosNaoClassificados}
                      />

                      <PrivateRoute
                        exact
                        permissionRequired="relatorios.entrada-producao"
                        path={URLS.ENTRADA_PRODUCAO}
                        component={ConferenciaEntradaProducao}
                      />

                      <PrivateRoute
                        exact
                        permissionRequired="relatorios.conferencia-bloco"
                        path={URLS.CONFERENCIA_BLOCOS}
                        component={ConferenciaBlocos}
                      />

                      <PrivateRoute
                        exact
                        permissionRequired="relatorios.conferencia-patio"
                        path={URLS.CONFERENCIA_PATIO_BENEFICIADOS}
                        component={ConferenciaPatioBeneficiados}
                      />

                      <PrivateRoute
                        exact
                        permissionRequired="relatorios.conferencia-sessao"
                        path={URLS.CONFERENCIA_SESSAO}
                        component={ConferenciaSessao}
                      />

                      <PrivateRoute
                        exact
                        permissionRequired="relatorios.conferencia-rolos"
                        path={URLS.CONFERENCIA_ROLOS}
                        component={ConferenciaRolos}
                      />

                      <PrivateRoute
                        exact
                        permissionRequired="relatorios.volume-estoque-volume-embarcado"
                        path={URLS.VOLUME_ESTOQUE}
                        component={VolumeEstoque}
                      />

                      <PrivateRoute
                        exact
                        permissionRequired="relatorios.volume-estoque-volume-embarcado"
                        path={URLS.CLASSIFICACAO_FARDINHOS}
                        component={ClassificacaoFardinhos}
                      />

                      <PrivateRoute
                        path={URLS.MENU_RELATORIOS}
                        component={MenuRelatorios}
                        permissionRequired={[
                          'relatorios.conferencia-do-patio',
                          'relatorios.comparativo-maquinas',
                          'relatorios.conferencia-campo',
                          'relatorios.padroes-de-hvi',
                          'relatorios.estoque',
                          'relatorios.dashboard.classificacao-algodao',
                        ]}
                      />
                      <PrivateRoute
                        path={Path.CONFERENCIA_CAMPO}
                        exact
                        component={pageSwitch}
                        permissionRequired="relatorios.conferencia-do-campo"
                      />

                      <PrivateRoute
                        path={Path.CONFERENCIA_CAMPO_DETALHES}
                        exact
                        component={DetalhesConferenciaCampo}
                        permissionRequired="relatorios.conferencia-do-campo"
                      />

                      <PrivateRoute
                        path={Path.RELATORIO_PADROES_HVI}
                        exact
                        component={RelatorioPadroesHVI}
                        permissionRequired="relatorios.padroes-de-hvi"
                      />

                      <PrivateRoute
                        path={Path.COMERCIAL}
                        exact
                        component={Comercial}
                        permissionRequired="comercial.listagem"
                      />

                      <PrivateRoute
                        path={Path.COMERCIAL_DETALHES_CONTRATO}
                        exact
                        component={DetalhesGrupoCliente}
                        permissionRequired="comercial.detalhes"
                      />

                      <PrivateRoute
                        path={Path.IMPORTACAO_ACTS}
                        component={ImportacaoActs}
                        permissionRequired="importacao.web.codigo-acts"
                      />

                      <PrivateRoute
                        path={Path.ESTOQUE_RELATORIO}
                        component={EstoqueRelatorio}
                        permissionRequired="relatorios.estoque"
                      />

                      <PrivateRoute
                        path={Path.ESTOQUE_COMERCIAL}
                        component={EstoqueComercial}
                        permissionRequired="comercial.visualizar-estoque"
                      />

                      <PrivateRoute
                        path={Path.EMAILS_BENEFICIAMENTO_DIARIO}
                        component={EmailsBeneficiamentoDiario}
                        permissionRequired="email-beneficiamento-diario.web.visualizar"
                      />

                      <PrivateRoute
                        path={Path.CARGA_EM_ANDAMENTO}
                        component={CargaEmAndamento}
                        permissionRequired="carga.web.visualizar"
                      />

                      <PrivateRoute
                        path={URLS.CARREGAMENTOS}
                        component={ListagemCarregamentos}
                        permissionRequired="carregamentos.listagem"
                      />

                      <PrivateRoute
                        path={Path.FARDINHOS_NO_PORTO}
                        component={FardinhosNoPorto}
                        permissionRequired="carregamentos.listagem"
                      />
                    </Switch>
                  </Suspense>
                </Layout>
              </SnackbarProvider>
            );
          case AuthenticationState.InProgress:
            return <Loader />;
          default:
        }
      }}
    </AzureAD>
  );
}
